body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  height: 100%;
}

code {
  font-family: "Manrope", sans-serif;
}
